export const GOOGLR_URL = "https://oauth2.googleapis.com/token"

export const GOOGLE_CLIENT_ID = "601221483061-eadrdpe1opnslp4sug89v8mpugebj68f.apps.googleusercontent.com"
export const GOOGLE_SECRECT_ID = "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"

export const ORG_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_enterprise/organisation&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const AGENCY_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_recruitment/agency&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const RES_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_talent/resource&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const IND_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_individual/individual&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const LYR_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/signup_lawyer/lawyer&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`
export const LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://app.agreementpaper.com/login&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline`