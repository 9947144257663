import { put } from "redux-saga/effects";
import * as action_type from "../../../Store/Constant";
import { API_URL, HEADERS } from "../../../Axios/axiosData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import * as google from "../../../Axios/google_oauth";

export function* signupSaga({ payload }) {
  const { setDisabledRegisterButton, ...restPayload } = payload;
  let data = { response: false, backend_data: null };
  yield axios
    .post(API_URL + "accounts/signup/", restPayload, HEADERS)
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
      localStorage.setItem("signUp_data", JSON.stringify({ email: payload.email }));

      if (payload.user_type === "ORGANISATION_USER") {
        data.path = "/signup_enterprise/workspace";
      } else if (payload.user_type === "AGENCY_USER" || payload.user_type === 'LAWYER_USER') {
        data.path = "/signup_recruitment/workspace";
      } else if (payload.user_type === "RESOURCE_USER" || payload.user_type === "INDIVIDUAL_USER") {
        data.path = "/verify-resource";
      }
      setDisabledRegisterButton(false);
    })
    .catch((err) => {
      data.backend_data = err;
      if (err?.response?.data?.email) {
        toast.error(err?.response?.data?.email[0]);
      } else {
        toast.error(err?.message);
      }
      setDisabledRegisterButton(false);
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* loginSaga({ payload, callback }) {

  let data = { response: false, backend_data: null };
  yield axios
    .post(API_URL + "accounts/login/", payload, HEADERS)
    .then((resp) => {
      if (resp.data.status < 205) {


        data.response = true;
        data.backend_data = resp.data;
        localStorage.setItem("access_token", resp.data.key);
        localStorage.setItem("user_data", JSON.stringify(resp.data.payload));
        callback(false)
        if (resp.data.payload.user_type === "ORGANISATION_USER") {
          data.path = "/organisation";
        } else if (resp.data.payload.user_type === "AGENCY_USER") {
          data.path = "/agency";
        } else if (resp.data.payload.user_type === "RESOURCE_USER") {
          data.path = "/resource";
        } else if (resp.data.payload.user_type === "INDIVIDUAL_USER") {
          data.path = "/individual";
        } else if (resp.data.payload.user_type === 'LAWYER_USER'){
          data.path = "/lawyer";
        }
      } else if (resp.data.status === 302) {
        callback(false)
        data.response = true;
        toast.error(resp?.data?.message);
        localStorage.setItem("signUp_data", JSON.stringify({ email: payload.email })
        );
        if (resp.data.user_type === "ORGANISATION_USER") {
          data.path = "/signup_enterprise/verify";
        } else if (resp.data.user_type === "AGENCY_USER") {
          data.path = "/signup_recruitment/verify-agency";
        } else if (resp.data.user_type === "RESOURCE_USER") {
          data.path = "/verify-resource";
        }
      } else {
        callback(false)
        if (resp?.data?.message) {
          toast.error(resp?.data?.message);
          callback(false)
        } else {
          toast.error("Try Again!");
          callback(false)
        }
      }
    })
    .catch((err) => {
      toast.error(err.message);
      callback(false)
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* getOtpSaga({ payload }) {
  let data = { response: false, backend_data: null };

  yield axios
    .post(API_URL + "accounts/verify-mail/", payload, HEADERS)
    .then((resp) => {
      if (resp.data.status < 205) {
        data.response = true;
        data.backend_data = resp.data;
        localStorage.setItem("access_token", resp.data.key);
        localStorage.setItem("user_data", JSON.stringify(resp.data.payload));


        if (resp.data.payload.user_type === "ORGANISATION_USER") {
          data.path = "/organisation";
        } else if (resp.data.payload.user_type === "AGENCY_USER") {
          data.path = "/agency";
        } else if (resp.data.payload.user_type === "RESOURCE_USER") {
          data.path = "/resource";
        } else if (resp.data.payload.user_type === "INDIVIDUAL_USER") {

          data.path = "/individual";
        } else if (resp.data.payload.user_type === "LAWYER_USER") {

          data.path = "/lawyer";
        }

        localStorage.removeItem("signUp_data");
      } else {
        toast.error(resp.data.message);
      }
    })
    .catch((err) => {
      data.backend_data = err;
      if (err?.response?.data?.email) {
        toast.error(err?.response?.data?.email[0]);
      } else {
        toast.error(err.message);
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* countrySaga() {
  let data = { response: false, backend_data: [], state_list: [] };
  yield put({ type: action_type.SET_LOADING, payload: true });

  const profile_data = JSON.parse(localStorage.getItem("user_data"));
  yield axios.get(API_URL + "accounts/country/", HEADERS).then((resp) => {
    data.response = true;
    for (let i of resp.data) {
      let x = { id: i.id, label: i.name, currency: i.currency, states: [] ,bank_code:i.bank_code,account_number:i.account_number

      };
      data.backend_data.push(x);
      for (let s of i.states) {
        x.states.push({ label: s.name });
      }

      if (profile_data) {
        if (profile_data.profile.country_name === i.name) {
          data.state_list = x.states;
        }
      }
    }
  });

  yield put({ type: action_type.SET_COUNTRY, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* skillsSaga() {
  yield put({ type: action_type.SET_LOADING, payload: true });
  let data = { response: false, backend_data: null };

  yield axios.get(API_URL + "resources/skill/", HEADERS).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_SKILL_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* google_key_get_saga({ payload, user_type }) {
  let data = { response: false, resp_data: {} };
  yield axios
    .post(google.GOOGLR_URL, payload, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((resp) => {
      data.response = true;
      data.resp_data["access_token"] = resp.data.access_token;
      if (user_type) {
        data.resp_data["user_type"] = user_type;
      } else {
        toast.error("No Account Found! Please Google Sign Up First");
      }
    })
    .catch((err) => {
      toast.error("Authentication Token is Invalid!");
    });

  if (data?.response) {
    yield put({ type: action_type.GOOGLE_LOGIN, payload: data.resp_data });
    yield put({ type: action_type.SET_LOADING, payload: true });
  }
}

export function* google_key_login_saga({ payload }) {
  let data = { response: false, backend_data: null };
  yield axios
    .post(API_URL + "accounts/dj-rest-auth/google/", payload, HEADERS)
    .then((resp) => {
      data.response = true;
      data.backend_data = resp.data;
      localStorage.setItem("access_token", resp.data.key);
      localStorage.setItem("user_data", JSON.stringify(resp.data.user));

      if (resp.data.user.user_type === "ORGANISATION_USER") {
        data.path = "/organisation";
      } else if (resp.data.user.user_type === "AGENCY_USER") {
        data.path = "/agency";
      } else if (resp.data.user.user_type === "RESOURCE_USER") {
        data.path = "/resource";
      }

      toast.success("Redirecting Dashboard....");
    })
    .catch((err) => {
      if (err?.response?.data[0]) {
        toast.error(err?.response?.data[0]);
      } else {
        toast.error(err.message);
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}

export function* linkedinLogin({ payload }) {
  let data = { response: false, backend_data: null };
  yield axios
    .post(`${API_URL}accounts/dj-rest-auth/linkedin/`, payload)
    .then((resp) => {
      if (resp?.data?.status < 205) {
        data.response = true;
        data.backend_data = resp.data;
        localStorage.setItem("access_token", resp.data.key);
        localStorage.setItem("user_data", JSON.stringify(resp.data.payload));

        if (resp.data.payload.user_type === "ORGANISATION_USER") {
          data.path = "/organisation";
        } else if (resp.data.payload.user_type === "AGENCY_USER") {
          data.path = "/agency";
        } else if (resp.data.payload.user_type === "RESOURCE_USER") {
          data.path = "/resource";
        }

        localStorage.removeItem("signUp_data");
        toast.success("Redirecting to Dashboard....");
      } else {
        toast.error(resp.data.message);
      }
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
    });

  yield put({ type: action_type.GET_NAVIGATE, data });
  yield put({ type: action_type.SET_LOADING, payload: false });

}

export function* currencyListData() {
  yield put({ type: action_type.SET_LOADING, payload: true });
  let data = { response: false, backend_data: null };

  yield axios.get(API_URL + "accounts/currency/", HEADERS).then((resp) => {
    data.response = true;
    data.backend_data = resp.data;
  });

  yield put({ type: action_type.SET_ALL_CURRENCY_LIST, data });
  yield put({ type: action_type.SET_LOADING, payload: false });
}
